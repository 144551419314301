import http from "../http";
import axios from "axios";

/**
 * 查询——物料库存查询
 * 
 */
export const materialInventorySearch = (currentPage, pageSize,data) => {
  return http({
    method: "post",
    url: process.env.VUE_APP_SCM_URL + `inventory/v1/MaterialInventory/fetchMaterialInventoryDetails?currentPage=${currentPage}&pageSize=${pageSize}`,
    data
  });
};


/**
 * 根据产品编码查询是产品数据
 */
 export const productQueryAPI = (productCode) => {
  return http({
    method: 'get',
    url: process.env.VUE_APP_PRODUCT_URL + `product/v1/product/queryProductAndProductFinished?productCode=${productCode}`
  })
}