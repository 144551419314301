var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container fabricMaterials" }, [
    _c("div", [
      _c("div", { staticClass: "actions_part clearfix" }, [
        _c("div", { staticClass: "actions_wrap" }, [
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "red" }, [_vm._v("*")]),
              _c("span", { staticClass: "tag" }, [
                _vm._v("\n              仓位：\n            ")
              ]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { filterable: "", placeholder: "请选择" },
                  on: { change: _vm.virtualInventoryChange },
                  model: {
                    value: _vm.inventory,
                    callback: function($$v) {
                      _vm.inventory = $$v
                    },
                    expression: "inventory"
                  }
                },
                _vm._l(_vm.inventoryList, function(item) {
                  return _c("el-option", {
                    key: item.index,
                    attrs: { label: item.name, value: item.index }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "red" }, [_vm._v("*")]),
              _c("span", { staticClass: "tag" }, [_vm._v("库位：")]),
              _c(
                "el-select",
                {
                  staticClass: "select_single w200 mr10",
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.subInventory,
                    callback: function($$v) {
                      _vm.subInventory = $$v
                    },
                    expression: "subInventory"
                  }
                },
                [
                  _c("el-option", {
                    key: "",
                    attrs: { label: "全部", value: "" }
                  }),
                  _vm._l(_vm.subInventoryList, function(item) {
                    return _c("el-option", {
                      key: item.subInventory,
                      attrs: {
                        label: item.subInventoryName,
                        value: item.subInventory
                      }
                    })
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("产品编号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "模糊输入" },
                nativeOn: {
                  keyup: function($event) {
                    return _vm.combinedProductRemote($event)
                  }
                },
                model: {
                  value: _vm.productCode,
                  callback: function($$v) {
                    _vm.productCode = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "productCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "模糊输入" },
                nativeOn: {
                  keyup: [
                    function($event) {
                      return _vm.combinedMaterialRemote($event)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchHandle($event)
                    }
                  ]
                },
                model: {
                  value: _vm.materialCode,
                  callback: function($$v) {
                    _vm.materialCode =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "materialCode"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("预留ID：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.batchNo,
                  callback: function($$v) {
                    _vm.batchNo = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "batchNo"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("工厂包号：")]),
              _c("el-input", {
                staticClass: "input_single w200 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchHandle($event)
                  }
                },
                model: {
                  value: _vm.factoryBatchNumber,
                  callback: function($$v) {
                    _vm.factoryBatchNumber =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "factoryBatchNumber"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "display_ib mr10 mb10" },
            [
              _c("span", { staticClass: "tag" }, [_vm._v("数量：")]),
              _c("el-input", {
                staticClass: "input_single w100 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: function($event) {
                    _vm.quantityStart = _vm.Inputquantity(_vm.quantityStart)
                  }
                },
                model: {
                  value: _vm.quantityStart,
                  callback: function($$v) {
                    _vm.quantityStart = _vm._n($$v)
                  },
                  expression: "quantityStart"
                }
              }),
              _vm._v("米\n            "),
              _c("span", { staticClass: "mr10" }, [_vm._v("—")]),
              _c("el-input", {
                staticClass: "input_single w100 mr10",
                attrs: { placeholder: "请输入" },
                nativeOn: {
                  keyup: [
                    function($event) {
                      _vm.quantityEnd = _vm.Inputquantity(_vm.quantityEnd)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchHandle($event)
                    }
                  ]
                },
                model: {
                  value: _vm.quantityEnd,
                  callback: function($$v) {
                    _vm.quantityEnd = _vm._n($$v)
                  },
                  expression: "quantityEnd"
                }
              }),
              _vm._v("米\n          ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "actions_btn_wrap down t_right" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.excelDownload } },
              [_vm._v("导出Excel")]
            ),
            _c("el-button", {
              attrs: {
                size: "small",
                type: "primary",
                icon: "el-icon-search",
                circle: "",
                title: "搜索"
              },
              on: { click: _vm.searchHandle }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table_part clearfix" },
        [
          _c(
            "el-tabs",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              staticClass: "subnav_wrap",
              staticStyle: { "min-height": "536px" }
            },
            [
              _c("singleTable", {
                attrs: {
                  tableList: _vm.materialInventoryList,
                  tableLeaderFieldsList: _vm.leaderFieldsList,
                  tableTailFieldsList: _vm.tailFieldsList,
                  tableFieldsList: _vm.fieldsList
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination_wrap fr" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": [50, 100, 300],
                  "page-size": _vm.pageSize,
                  layout: "sizes, total, prev, pager, next",
                  total: this.total
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function($event) {
                    _vm.currentPage = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }